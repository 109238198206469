html {
  overflow-x: hidden;
}

body {
  font-family: 'Viner Hand ITC', cursive;
}

body input {
  font-family: 'Times New Roman';
}

body h1 {
  font-family: 'Segoe Script', cursive;
}

body a {
  font-family: 'Segoe Script', cursive;
}

body p {
  font-family: 'Times New Roman';
  font-size: large;
}

textarea {
  border:solid 1px;
  border-color: purple;
  font-family: 'Times New Roman';
}

input {
  border: none;
  background-color: transparent;
  outline: none;
}

input[type="checkbox"] {
  border-color: purple;
}

.textArea {
  border:solid 1px;
  border-color: purple;
  width: 100%;
  height: 200px;
}

.textArea:focus {
  border-color: purple;
  border: 2px solid purple;
}

.passwordTextBox {
  border: 0;
  border: solid 1px;
  border-color: purple;
  border-radius: 5px;
  width: 100%;
}

.passwordTextBox:focus {
  border-color: purple;
  border: 2px solid purple;
  border-radius: 5px;
}

.boxsizingBorder {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.text-area-wrapper {
  position: relative;
}

.hidden-textarea {
  -webkit-text-security: disc;
  -moz-text-security: disc;
}

.fas {
  font-family: FontAwesome;
  font-size: 20px;
  color: purple;
}

.copy-button {
  position: absolute;
  top: 10px;
  right: 11px;
  background-color: transparent;
  border: none;
}

.copy-button-textbox {
  position: absolute;
  top: -1px;
  right: 10px;
  background-color: transparent;
  /* border: 2px solid purple;
  border-radius: 5px; */
  border: none;
}

.eyeIcon {
  position: absolute;
  right: 20px;
}

.password-toggle-input {
  position: relative;
  display: flex;
  align-items: center;
}

.relative-container {
  position: relative;
  display: flex;
  align-items: center;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  background-color: purple;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 5px;

  position: absolute;
  z-index: 1;
  top: 40px;
  left: -30px;
  transform: translateX(-50%);

  opacity: 0;
  transition: opacity 0s;
  width: 300px;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.custom-tooltip .tooltip-inner {
  background-color: purple;
}

.custom-tooltip.show.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: purple !important;
}

@media (max-width: 596px) {

  .mainContent {
    margin-left: 20px;
    width: 95%;
  }

  .adClass {
    width: 100px;
  }

  .tooltip-text {
    width: 70vw;
  }

  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

}

@media (max-width: 901px)
{

  .adClass {
    width: 125px;
  }

}

@media (min-width: 901px)
{
  .adClass {
    width: 150px;
  }
}